<!--发货单调整-->
<template>
    <div class="invoiceAdjust">
        <div class="item">
            <label>砼方量:</label>
            <el-input
                v-model="formData.concrete_volume"
                placeholder="请输入砼方量"
                type="number"
                @input="updateVolume"
            >
            </el-input>
        </div>
        <div class="item">
            <label>砂浆方量:</label>
            <el-input
                v-model="formData.mortar_volume"
                placeholder="请输入砂浆方量"
                type="number"
                @input="updateVolume"
            >
            </el-input>
        </div>
        <div class="item">
            <label>发货方量:</label>
            <el-input
                v-model="formData.volume"
                readonly
                type="number"
            >
            </el-input>
        </div>
        <div class="item">
            <label>退砼方量:</label>
            <el-input
                v-model="formData.back_volume"
                readonly
                type="number"
            >
            </el-input>
        </div>
        <div class="adjust-wrapper_footer">
            <el-button
                type="primary"
                :loading="btnLoading"
                class="btn confirm_btn send-btn"
                @click="saveEditFhd"
            >
                保存
            </el-button>
            <el-button class="btn" @click="$parent.hide()">
                取消
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'invoice-adjust',
    props: {
        extr: Object,
    },
    data() {
        return {
            formData: {
                pcb_number: '',
                concrete_volume: 0,
                mortar_volume: 0,
                volume: 0,
                back_volume: 0,
            },
            btnLoading: false,
        };
    },
    created() {
        this.formData.pcb_number = this.extr.row.pcb_number;
        this.formData.concrete_volume = this.extr.row.concrete_volume;
        this.formData.mortar_volume = this.extr.row.mortar_volume;
        this.formData.volume = this.extr.row.volume;
        this.formData.back_volume = this.extr.row.back_volume;
    },
    methods: {
        // 保存编辑发货单
        saveEditFhd() {
            this.btnLoading = true;
            this.$axios
                .post('/interfaceApi/production/productpcb/modify_volume', this.formData )
                .then(() => {
                    this.btnLoading = false;
                    this.$message.success('保存成功');
                    this.$parent.hide();
                    window.updateTable();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 更新发货方量
        // 发货方量=砼方量+砂浆方量-退砼方量
        updateVolume() {
            this.formData.volume = Number(this.formData.concrete_volume) + Number(this.formData.mortar_volume) - Number(this.formData.back_volume);
        },
    },
};
</script>

<style scoped lang="stylus">
    .invoiceAdjust
        width 100%;
        height 100%;
        display flex
        flex-wrap wrap
        padding-top 0.3rem;
        .item
            box-sizing border-box
            width 50%;
            padding 0.1rem 0.2rem;
            display flex
            align-items center
            label
                display flex
                width 0.8rem;
                text-align right
            .el-input
                flex 1;
        .adjust-wrapper_footer
            width 100%
            display flex
            justify-content center
            padding-top 0.3rem;
            padding-bottom 0.2rem;
            .btn
                width 1.84rem
                height 0.44rem
                border 0.02rem solid rgba(21,119,210,1)
                color #1577D2
                background #fff
                display inline-block
                cursor pointer
                margin 0.1rem 0.2rem
            .confirm_btn
                color #ffffff
                border none
                background rgba(21,119,210,0.4)
                cursor default
            .send-btn
                background #1577D2
                cursor pointer
</style>
